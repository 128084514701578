html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "VWAGSansRegular";
  src: local("VWAGSansRegular"),
  url("./assets/fonts/VWAG_TheSans_TrueType/VWAGTheSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "VWAGSansRegularBold";
  src: local("VWAGSansRegularBold"),
  url("./assets/fonts/VWAG_TheSans_TrueType/VWAGTheSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

